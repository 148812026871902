import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	addDays,
	isPast,
	parseISO,
	setHours,
	setMilliseconds,
	setMinutes,
	setSeconds,
} from "date-fns"
import React, { useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { Link, useNavigate } from "react-router-dom"
import { supabase } from "../../lib/supabaseClient"
import * as ROUTES from "../../constants/routes"
import executeQuery from "../../helpers/executeQuery"
import { useSession } from "../../context/SessionContext"

export default function AddLocationPage() {
	const navigate = useNavigate()
	const { session } = useSession()
	let userId = session?.user.id

	const [currentVisit, setCurrentVisit] = useState(null)
	const [currentLocationId, setCurrentLocationId] = useState(null)
	const [drinkCount, setDrinkCount] = useState(0)
	const [expireTime, setExpireTime] = useState(null)
	const [timestamp, setTimestamp] = useState(null)

	// GET VISIT
	useEffect(() => {
		const fetchCurrentVisit = async () => {
			if (userId) {
				const { data, loading } = await executeQuery(
					supabase
						.from("profiles")
						.select(
							"current_visit_id, user_visits(id, location_id, locations(name), expires, user_visit_drinks(count))"
						)
						.eq("user_id", userId)
						.single()
				)
				if (data) {
					let user_visits = data.user_visits
					if (user_visits) {
						const givenDate = parseISO(user_visits.expires)
						const hasPassed = isPast(givenDate)
						user_visits = hasPassed ? null : user_visits
						setCurrentVisit(user_visits)
					}
				}
			} else {
				setCurrentVisit(null)
			}
		}

		if (currentVisit === null) {
			fetchCurrentVisit()
		}
	}, [currentVisit, userId])

	// LOCATION
	useEffect(() => {
		if (currentVisit) {
			setCurrentLocationId(currentVisit.location_id)
		}
	}, [currentVisit])

	// COUNT
	useEffect(() => {
		if (currentVisit) {
			setDrinkCount(currentVisit.user_visit_drinks[0].count)
		}
	}, [currentVisit])

	// TIME
	useEffect(() => {
		const currentDate = new Date()
		let tempExpireTime
		// If it's already past 8 AM today, set it for 8 AM tomorrow
		if (currentDate.getHours() >= 8) {
			tempExpireTime = setMilliseconds(
				setSeconds(setMinutes(setHours(addDays(currentDate, 1), 8), 0), 0),
				0
			)
		} else {
			// If it's before 8 AM today, set it for 8 AM today
			tempExpireTime = setMilliseconds(
				setSeconds(setMinutes(setHours(currentDate, 8), 0), 0),
				0
			)
		}
		tempExpireTime = tempExpireTime.toJSON()
		setExpireTime(tempExpireTime)
		let tempTimestamp = currentDate.toJSON()
		setTimestamp(tempTimestamp)
	}, [currentVisit])

	// Handle search
	const [error, setError] = useState(false)
	const [locationId, setLocationId] = useState()

	const [query, setQuery] = useState("")
	const [searchResults, setSearchResults] = useState([])

	useEffect(() => {
		const handleSearch = async () => {
			const { data, loading } = await executeQuery(
				supabase
					.from("locations")
					.select(`name, id, address`)
					.ilike("name", `%${query}%`)
					.limit(5)
			)

			setSearchResults(data)
		}

		if (query.trim() === "") {
			setSearchResults([])
			return
		}
		handleSearch()
	}, [query])

	function handleResetSearch(e) {
		e.preventDefault()
		setQuery("")
		setError(false)
		setLocationId()
	}

	function handleOnChange(e) {
		setError(false)
		setQuery(e.target.value)
	}

	// new session
	async function addNewVisit(obj) {
		const { data, loading } = await executeQuery(
			supabase.from("user_visits").insert([obj]).select()
		)
		await executeQuery(
			supabase
				.from("profiles")
				.update({ current_visit_id: obj.id })
				.eq("user_id", userId)
				.select()
		)
		return data
	}

	//update session
	async function updateCurrentVisit(obj) {
		const { data, loading } = await executeQuery(
			supabase
				.from("user_visits")
				.update({ location_id: obj.location_id })
				.eq("id", currentVisit.id)
				.select()
		)
		return data
	}

	async function handleOnSubmit(e) {
		e.preventDefault()

		try {
			if (!locationId) {
				throw new Error("Please add location")
			}

			let newObj = {
				id: uuidv4(),
				user_id: userId,
				location_id: locationId,
				timestamp: timestamp,
				expires: expireTime,
			}

			// Checklist
			let state = "new"

			let has_current_visit = currentVisit !== null
			let has_no_drinks = drinkCount === 0
			let is_same_location = currentLocationId === locationId

			if (has_current_visit && is_same_location && has_no_drinks) {
				state = "update"
			} else if (has_current_visit && is_same_location) {
				state = "update"
			} else if (has_current_visit) {
				state = "new"
			}

			if (state === "new") {
				await addNewVisit(newObj)
			} else {
				await updateCurrentVisit(newObj)
			}
			navigate(ROUTES.ADD_ATTENDEES)
		} catch (error) {
			setError(true)
			console.error(error.message)
		}
	}

	function handleReturn() {
		navigate(ROUTES.DASHBOARD)
	}

	return (
		<>
			<div className="page-dashboard">
				<div className="container">
					<main>
						<div>
							<p>
								<FontAwesomeIcon
									icon={`arrow-left`}
									style={{ cursor: "pointer" }}
									onClick={() => handleReturn()}
								/>
							</p>
							{currentVisit && (
								<div style={{ opacity: 0.4 }}>
									Moved on from <strong>{currentVisit?.locations?.name}</strong>
									?
								</div>
							)}
							<h3>Where are you{currentVisit ? " now" : ""}?</h3>
							<form method="post" onSubmit={handleOnSubmit}>
								<fieldset>
									<label htmlFor="location">Your location:</label>
									<div className="search-bar">
										<button>
											<FontAwesomeIcon
												icon={`${locationId ? "check" : "search"}`}
											/>
										</button>
										<input
											id="location"
											autoComplete="off"
											className="input"
											placeholder={`_`}
											type="text"
											name="location"
											value={query || ""}
											onChange={handleOnChange}
										/>
										<button onClick={handleResetSearch}>
											<FontAwesomeIcon icon={`times`} />
										</button>
									</div>
									{error && <div className="error">Please add location</div>}
									{!locationId && query && query.length > 0 && (
										<div className="search-results">
											<ul>
												{searchResults.map((result) => {
													return (
														<li
															key={result.id}
															onClick={() => {
																setQuery(result.name)
																setLocationId(result.id)
																setSearchResults([])
															}}>
															<div>{result.name}</div>
															<div>
																<small>{result.address}</small>
															</div>
														</li>
													)
												})}
											</ul>
										</div>
									)}
								</fieldset>
								<fieldset>
									<input
										name="location-id"
										value={locationId || ""}
										onChange={(e) => setLocationId(e.target.value)}
										style={{ display: "none" }}
									/>
									<button className="button">Submit</button>
								</fieldset>
							</form>
							<p style={{ textAlign: "center" }}>
								<Link to={`../new-location`}>
									Not in the list? <strong>Add it</strong>!
								</Link>
							</p>
						</div>
					</main>
				</div>
			</div>
		</>
	)
}
