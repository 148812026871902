import React, { useEffect } from "react"
import { useLoaderData } from "react-router-dom"
import LocationNav from "../../components/profile/LocationNav"
import MapStatic from "../../components/MapStatic"
import Socials from "../../components/Socials"
import copy from "copy-to-clipboard"
import { APP_NAME } from "../.."

export default function LocationPage() {
	const data = useLoaderData()
	const location = data.data
	const coordinates = data.coordinates
	const lng = coordinates[0]
	const lat = coordinates[1]

	const copyToClipboard = (e) => {
		copy(e.target.innerText)
		alert(`Copied "${e.target.innerText}"`)
	}

	useEffect(() => {
		document.title = `${location.name} • ${APP_NAME}`
	}, [location])

	return (
		<>
			<LocationNav />
			<div className="profile-location main-content">
				<MapStatic lng={lng} lat={lat} zoom={14} />
				<div className="content">
					<div className="container">
						<h1 className="title">{location.name}</h1>
						<div className="type">
							<div className="typeW">{location.location_types.name}</div>
						</div>
						<div className="address">{location.address}</div>
						{location.wifi && (
							<div className="wifi">
								<p>
									<b>WiFi password:</b>{" "}
									<span onClick={copyToClipboard}>{location.wifi}</span>
									<br />
									<small>(click password to copy)</small>
								</p>
							</div>
						)}
						<Socials
							instagram={location.instagram}
							twitter={location.twitter}
							website={location.website}
						/>
					</div>
				</div>
			</div>
		</>
	)
}
