import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { supabase } from "../../lib/supabaseClient"
import toTitleCase from "../../helpers/toTitleCase"
import Select from "react-select"
import { format } from "date-fns"

export default function NewDrinkPage() {
	const navigate = useNavigate()

	const [alcoholPercentage, setAlcoholPercentage] = useState("")
	const [alcoholPercentageError, setAlcoholPercentageError] = useState(false)

	const [name, setName] = useState("")
	const [nameError, setNameError] = useState(false)

	const [countries, setCountries] = useState([])
	const [selectedCountry, setSelectedCountry] = useState(null)
	const [countryError, setCountryError] = useState(false)

	const [types, setTypes] = useState([])
	const [selectedType, setSelectedType] = useState(null)
	const [typeError, setTypeError] = useState(false)

	function handleOnSubmit(e) {
		e.preventDefault()
		if (name === "") {
			setNameError(true)
			return
		}

		if (alcoholPercentage === "") {
			setAlcoholPercentageError(true)
			return
		}
		if (selectedCountry === null) {
			setCountryError(true)
			return
		}
		if (selectedType === null) {
			setTypeError(true)
			return
		}
		console.log(selectedCountry)

		let currentTimestamp = format(new Date(), "yyyy-MM-dd HH:mm:ssXXX")

		let newDrink = {
			alcohol_percentage: alcoholPercentage,
			country: selectedCountry.value,
			name: name ? toTitleCase(name) : "",
			type: selectedType.value,
			updated_at: currentTimestamp,
		}

		updateDatabase(newDrink)

		navigate(-1)
	}

	useEffect(() => {
		const fetchCountries = async () => {
			try {
				const { data, error } = await supabase
					.from("countries")
					.select("name_en, id")

				if (error) {
					throw error
				}

				setCountries(data || [])
			} catch (error) {
				console.error("Error fetching countries:", error.message)
			}
		}

		fetchCountries()
	}, [])

	const handleCountryChange = (selectedOption) => {
		setCountryError(false)
		setSelectedCountry(selectedOption)
	}

	useEffect(() => {
		const fetchTypes = async () => {
			try {
				const { data, error } = await supabase
					.from("drink_types")
					.select("name, id")

				if (error) {
					throw error
				}

				setTypes(data || [])
			} catch (error) {
				console.error("Error fetching types:", error.message)
			}
		}

		fetchTypes()
	}, [])

	const handleTypeChange = (selectedOption) => {
		setTypeError(false)
		setSelectedType(selectedOption)
	}

	async function updateDatabase(newDrink) {
		const { error } = await supabase.from("drinks").insert(newDrink)
		if (error) {
			console.error(error)
		}
	}

	function handleReturn() {
		navigate(-1)
	}

	return (
		<div className="page-dashboard main-content">
			<div className="container">
				<main>
					<div>
						<p>
							<FontAwesomeIcon
								icon={`arrow-left`}
								style={{ cursor: "pointer" }}
								onClick={() => handleReturn()}
							/>
						</p>
						<h3>New drink</h3>
						<form onSubmit={handleOnSubmit}>
							<fieldset>
								<label>Name</label>
								<input
									type="text"
									value={name || ""}
									onSelect={() => setNameError(false)}
									onChange={(e) => {
										setName(e.target.value)
									}}
								/>
								{nameError && (
									<p className="error">Please enter a valid name</p>
								)}
							</fieldset>

							<fieldset>
								<label>Select Country:</label>
								<Select
									value={selectedCountry}
									onChange={handleCountryChange}
									options={countries.map((country) => ({
										label: country.name_en,
										value: country.id,
									}))}
								/>
								{countryError && (
									<p className="error">Please select a country</p>
								)}
							</fieldset>

							<fieldset>
								<label>Select Type:</label>
								<Select
									value={selectedType}
									onChange={handleTypeChange}
									options={types.map((type) => ({
										label: type.name,
										value: type.id,
									}))}
								/>
								{typeError && (
									<p className="error">Please select a drink type</p>
								)}
							</fieldset>

							<fieldset>
								<label>Alcohol percentage</label>
								<input
									type="number"
									step={0.1}
									value={alcoholPercentage || ""}
									onSelect={() => setAlcoholPercentageError(false)}
									onChange={(e) => setAlcoholPercentage(e.target.value)}
								/>
								{alcoholPercentageError && (
									<p className="error">
										Please enter a valid alcohol percentage
									</p>
								)}
							</fieldset>
							<fieldset>
								<button className="button">Submit</button>
							</fieldset>
						</form>
					</div>
				</main>
			</div>
		</div>
	)
}
