import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useNavigate, useNavigation, useOutletContext } from "react-router-dom"
import Spinner from "../../components/Spinner"
import { RECENT_SEARCHES_STORAGE_KEY } from "../../constants/paths"
import * as ROUTES from "../../constants/routes"
import { supabase } from "../../lib/supabaseClient"
import executeQuery from "../../helpers/executeQuery"

function ResultDrink({ drink, handleOnClick }) {
	return (
		<li onClick={() => handleOnClick(drink)}>
			<div className="result" data-type="drink">
				<div className="icon">
					<FontAwesomeIcon icon={`fas fa-beer`} />
				</div>
				<div className="text">
					<div className="name">{drink.name}</div>
					<div className="type">
						{drink.drink_types.name} • {drink.alcohol_percentage}%
					</div>
				</div>
			</div>
		</li>
	)
}

export default function ResultsDrinksPage() {
	const navigate = useNavigate()
	const navigation = useNavigation()
	const { query, storedSearches } = useOutletContext()

	const [recentDrinks, setRecentDrinks] = useState()

	const [allDrinks, setAllDrinks] = useState([])

	const [searchResults, setSearchResults] = useState([])

	useEffect(() => {
		if (query.trim() === "") {
			setSearchResults([])
			return
		}
		handleSearch()
		// eslint-disable-next-line
	}, [query])

	const handleSearch = async () => {
		// Make a query to Supabase to search for data based on the searchTerm
		const { data, loading } = await executeQuery(
			supabase
				.from("drinks")
				.select(
					`
						name,
						id,
						alcohol_percentage,
						type,
						drink_types (
							name
						)
					`
				)
				.ilike("name", `%${query}%`)
		)
		setSearchResults(data)
	}

	useEffect(() => {
		if (storedSearches && storedSearches.recent_drinks) {
			setRecentDrinks(storedSearches.recent_drinks)
		} else {
			setRecentDrinks([])
		}
	}, [storedSearches])

	useEffect(() => {
		if (recentDrinks) {
			let updatedStoredSearches = {
				...storedSearches,
				recent_drinks: recentDrinks,
			}
			localStorage.setItem(
				RECENT_SEARCHES_STORAGE_KEY,
				JSON.stringify(updatedStoredSearches)
			)
		}
	}, [storedSearches, recentDrinks])

	async function handleOnClick(drink) {
		let filteredRecentDrinks = [...recentDrinks]
		filteredRecentDrinks = filteredRecentDrinks.filter(
			(item) => item.id !== drink.id
		)
		if (filteredRecentDrinks.length > 4) {
			filteredRecentDrinks.pop()
		}

		await setRecentDrinks([...filteredRecentDrinks, drink])
		await navigate(`${ROUTES.DRINK}/${drink.id}`)
	}

	useEffect(() => {
		searchResults && searchResults.length > 0 && setAllDrinks(searchResults)
	}, [searchResults])

	return (
		<>
			{query && query.length > 0 ? (
				navigation.state === "loading" ? (
					<Spinner />
				) : (
					<>
						<h3>Results</h3>
						<ul className="search-results">
							{allDrinks &&
								allDrinks.length > 0 &&
								allDrinks.map((drink) => (
									<ResultDrink
										key={drink.id}
										drink={drink}
										handleOnClick={handleOnClick}
									/>
								))}
						</ul>
					</>
				)
			) : (
				<>
					<h3>Recent Drink Searches</h3>
					<ul className="search-results reversed">
						{recentDrinks &&
							recentDrinks.length > 0 &&
							recentDrinks.map((drink) => (
								<ResultDrink
									key={drink.id}
									drink={drink}
									handleOnClick={handleOnClick}
								/>
							))}
					</ul>
				</>
			)}
		</>
	)
}
