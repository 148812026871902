import React, { useEffect } from "react"
import { Outlet, useOutletContext } from "react-router-dom"
import { APP_NAME } from "../.."

export default function DashboardLayout() {
	const { user, setIsWine, isWine } = useOutletContext()

	useEffect(() => {
		document.title = `Dashboard • ${APP_NAME}`
	}, [])
	return (
		<>
			<Outlet context={{ user, setIsWine, isWine }} />
		</>
	)
}
