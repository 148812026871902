import React from "react"

const Total = () => {
	return (
		<nav className="Total">
			<div className="title">Total</div>
		</nav>
	)
}

export default Total
