export const DASHBOARD = "/dashboard"

export const NOT_FOUND = "/not-found"

export const LANDING_PAGE = "/"

export const LOGIN = "/login"
export const UPDATE_PASSWORD_STEP_1 = "/update-password"
export const UPDATE_PASSWORD_STEP_2 = "/update-password-1"
export const SIGN_UP = "/sign-up"

export const ADD_LOCATION = "/dashboard/add-location"
export const ADD_ATTENDEES = "/dashboard/add-attendees"
export const ADD_DRINK = "/dashboard/add-drink"
export const ADD_WINE = "/dashboard/add-wine"

export const PROFILE = "/p"
export const LOCATION = "/explore/places"
export const DRINK = "/explore/beers"

export const ME = "/me"
export const EDIT_PROFILE = "/accounts/edit"
export const HISTORY = "/history"

export const EXPLORE = "/explore"

export const RANKINGS = "/rankings"
