import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NavLink } from "react-router-dom"
import * as ROUTES from "../constants/routes"
import AvatarIcon from "./AvatarIcon"

const FooterNav = ({ user }) => {
	return (
		<div className="footer-nav">
			<div>
				<NavLink to={ROUTES.DASHBOARD} className={"link"}>
					<FontAwesomeIcon icon={`house`} />
				</NavLink>
			</div>
			<div>
				<NavLink to={`${ROUTES.EXPLORE}`} className={"link"}>
					<FontAwesomeIcon icon={`search`} />
				</NavLink>
			</div>
			<div>
				<NavLink to={`${ROUTES.HISTORY}`} className={"link"}>
					<FontAwesomeIcon icon={`clock-rotate-left`} />
				</NavLink>
			</div>
			<div>
				<NavLink to={`${ROUTES.ME}`} className={"link"}>
					{user ? (
						<AvatarIcon url={user?.avatar} size={40} />
					) : (
						<FontAwesomeIcon icon={`user`} />
					)}
				</NavLink>
			</div>
		</div>
	)
}

export default FooterNav
