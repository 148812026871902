import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"

const UserProfileNav = ({ user }) => {
	const navigate = useNavigate()
	function handleCancel() {
		navigate(-1)
	}

	return (
		<div className="profile-nav">
			<div className="cancel" onClick={(e) => handleCancel(e)}>
				<FontAwesomeIcon icon={`fas fa-arrow-left`} />
			</div>
			<div className="title">{`@${user?.username}`}</div>
			<div className="menu"></div>
		</div>
	)
}

export default UserProfileNav
