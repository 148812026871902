import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { supabase } from "../../lib/supabaseClient"
import Select from "react-select"

export default function NewLocationPage() {
	const navigate = useNavigate()

	const [name, setName] = useState("")
	const [nameError, setNameError] = useState(false)

	const [types, setTypes] = useState([])
	const [selectedType, setSelectedType] = useState(null)
	const [typeError, setTypeError] = useState(false)

	const [address, setAddress] = useState("")
	const [addressError, setAddressError] = useState(false)

	const [city, setCity] = useState("Nice")
	const [cityError, setCityError] = useState(false)

	const [postCode, setPostCode] = useState("")
	const [postCodeError, setPostCodeError] = useState(false)

	const [website, setWebsite] = useState("")

	const [instagram, setInstagram] = useState("")

	const [wifi, setWifi] = useState("")

	function handleOnSubmit(e) {
		e.preventDefault()

		if (!name.length > 0) {
			setNameError(true)
			return
		}
		if (!address.length > 0) {
			setAddressError(true)
			return
		}
		if (!city.length > 0) {
			setCityError(true)
			return
		}
		if (!isValidPostcode(postCode)) {
			setPostCodeError(true)
			return
		}

		let newLocation = {
			name: toTitleCase(name),
			type: selectedType.value,
			address: `${toTitleCase(address)}, ${toTitleCase(city)} ${formatPostcode(
				postCode
			)}`,
			website: website,
			instagram: instagram,
			wifi: wifi,
		}

		updateDatabase(newLocation)

		navigate(-1)
	}

	useEffect(() => {
		const fetchTypes = async () => {
			try {
				const { data, error } = await supabase
					.from("location_types")
					.select("name, id")

				if (error) {
					throw error
				}

				setTypes(data || [])
			} catch (error) {
				console.error("Error fetching types:", error.message)
			}
		}

		fetchTypes()
	}, [])

	const handleTypeChange = (selectedOption) => {
		setTypeError(false)
		setSelectedType(selectedOption)
	}

	async function updateDatabase(newLocation) {
		const { error } = await supabase.from("locations").insert(newLocation)
		if (error) {
			console.error(error)
		}
	}

	function toTitleCase(str) {
		if (typeof str === "undefined") return
		return str.toLowerCase().replace(/(?:^|\s|\/|-)\w/g, function (match) {
			return match.toUpperCase()
		})
	}

	function isValidPostcode(code) {
		const postalCodePattern = /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/
		return postalCodePattern.test(code.toUpperCase())
	}

	function formatPostcode(p) {
		if (isValidPostcode(p)) {
			var postcodeRegEx = /(^[A-Z]{1,2}[0-9]{1,2})([0-9][A-Z]{2}$)/i
			return p.replace(postcodeRegEx, "$1 $2")
		} else {
			return p
		}
	}

	function handleReturn() {
		navigate(-1)
	}

	return (
		<div className="page-dashboard main-content">
			<div className="container">
				<main>
					<div>
						<p>
							<FontAwesomeIcon
								icon={`arrow-left`}
								style={{ cursor: "pointer" }}
								onClick={() => handleReturn()}
							/>
						</p>
						<h3>New location</h3>
						<form onSubmit={handleOnSubmit}>
							<fieldset>
								<label>Name</label>
								<input
									type="text"
									value={name || ""}
									onSelect={() => setNameError(false)}
									onChange={(e) => {
										setName(e.target.value)
									}}
								/>
								{nameError && (
									<p className="error">Please enter a valid name</p>
								)}
							</fieldset>

							<fieldset>
								<label>Select Type:</label>
								<Select
									value={selectedType}
									onChange={handleTypeChange}
									options={types.map((type) => ({
										label: type.name,
										value: type.id,
									}))}
								/>
								{typeError && (
									<p className="error">Please select a drink type</p>
								)}
							</fieldset>

							<fieldset>
								<label>Address</label>
								<input
									type="text"
									value={address || ""}
									onSelect={() => setAddressError(false)}
									onChange={(e) => setAddress(e.target.value)}
								/>
								{addressError && (
									<p className="error">Please enter a valid address</p>
								)}
							</fieldset>

							<fieldset>
								<div className="row">
									<div>
										<label>City</label>
										<input
											type="text"
											value={city || ""}
											onSelect={() => setCityError(false)}
											onChange={(e) => setCity(e.target.value)}
										/>
									</div>
									<div>
										<label>Post Code</label>
										<input
											type="text"
											value={postCode || ""}
											onSelect={() => setPostCodeError(false)}
											onChange={(e) => setPostCode(e.target.value)}
										/>
									</div>
								</div>
								{cityError && (
									<p className="error">Please enter a valid city</p>
								)}
								{postCodeError && (
									<p className="error">Please enter a valid post code</p>
								)}
							</fieldset>

							<fieldset>
								<label>Website</label>
								<input
									type="text"
									value={website || ""}
									placeholder="https://example.com"
									onChange={(e) => {
										setWebsite(e.target.value)
									}}
								/>
							</fieldset>

							<fieldset>
								<label>Instagram handle</label>
								<div
									style={{
										display: "flex",
										gap: 10,
										justifyContent: "center",
										alignItems: "center",
									}}>
									<div style={{ fontSize: "1.5em" }}>
										<b>@</b>
									</div>
									<input
										type="text"
										value={instagram || ""}
										placeholder="insta_handle"
										onChange={(e) => {
											setInstagram(e.target.value)
										}}
									/>
								</div>
							</fieldset>

							<fieldset>
								<label>Wifi password</label>
								<input
									type="text"
									placeholder="12345678"
									value={wifi || ""}
									onChange={(e) => {
										setWifi(e.target.value)
									}}
								/>
							</fieldset>

							<fieldset>
								<button className="button">Submit</button>
							</fieldset>
						</form>
					</div>
				</main>
			</div>
		</div>
	)
}
