import { format, parseISO } from "date-fns"
import React, { useEffect, useState } from "react"
import DateNav from "../../components/profile/DateNav"
import Stats from "../../components/profile/Stats"
import Total from "../../components/profile/Total"
import executeQuery from "../../helpers/executeQuery"
import { supabase } from "../../lib/supabaseClient"
import { useSession } from "../../context/SessionContext"

export default function MePage() {
	const { session } = useSession()
	let userId = session?.user.id

	const [loading, setLoading] = useState(true)

	// GET TODAY
	let today = new Date()
	today = today.getTime()
	const [currentDate, setCurrentDate] = useState(today)

	// GET USER VISITS
	const [totalVisits, setTotalVisits] = useState([])
	const [totalDrinks, setTotalDrinks] = useState([])
	const [totalAttendees, setTotalAttendees] = useState([])
	const [totalMl, setTotalMl] = useState(0)
	const [totalUnits, setTotalUnits] = useState(0)
	const [totalVenues, setTotalVenues] = useState(0)
	const [todaysVisits, setTodaysVisits] = useState([])
	const [todaysDrinks, setTodaysDrinks] = useState([])
	const [todaysAttendees, setTodaysAttendees] = useState([])
	const [todaysMl, setTodaysMl] = useState(0)
	const [todaysUnits, setTodaysUnits] = useState(0)
	const [todaysVenues, setTodaysVenues] = useState(0)

	// GET TOTAL VISITS
	useEffect(() => {
		function createVisitObject(obj) {
			const attendees = obj.attendees
			const drinks = obj.drinks
			const id = obj.id
			const location_id = obj.location.id
			const ml = obj.ml
			const timestamp = obj.timestamp

			let updatedAttendees = []
			if (attendees.length > 0) {
				updatedAttendees = attendees.map((item) =>
					createAttendeeObject(item, timestamp)
				)
			}

			let updatedDrinks = []
			if (drinks.length > 0) {
				updatedDrinks = drinks.map((item) => createDrinkObject(item))
			}

			return {
				attendees: updatedAttendees,
				drinks: updatedDrinks,
				id,
				location_id,
				ml,
				timestamp,
			}
		}

		function createAttendeeObject(obj, timestamp) {
			const id = obj.id
			const user_id = obj.user.user_id
			const username = obj.user.username

			return {
				id: id,
				timestamp: timestamp,
				user_id: user_id,
				username: username,
			}
		}

		function createDrinkObject(obj) {
			const alcohol_percentage = obj.drink.alcohol_percentage
			const id = obj.id
			const timestamp = obj.timestamp
			const ml = obj.ml

			const units = (0.8 * alcohol_percentage * ml) / 1000
			return {
				alcohol_percentage: alcohol_percentage,
				id: id,
				ml: ml,
				timestamp: timestamp,
				units: units,
			}
		}

		async function fetchVisits() {
			if (userId) {
				try {
					setLoading(true)

					const { data: userVisitsData } = await executeQuery(
						supabase
							.from("user_visits")
							.select(
								"id, timestamp, location:locations(id, name), drinks:user_visit_drinks(id, ml, timestamp, drink:drinks(alcohol_percentage)), attendees:user_visit_attendees(id, user:profiles(user_id, username))"
							)
							.eq("user_id", userId)
					)
					const formattedVisits = userVisitsData.map((visit) =>
						createVisitObject(visit)
					)
					setTotalVisits(formattedVisits)
					setLoading(false)
				} catch (error) {
					console.error("Error fetching data:", error)
					setLoading(false)
				}
			} else {
				setTotalVisits([])
			}
		}

		if (totalVisits.length === 0) {
			fetchVisits()
		}
	}, [totalVisits.length, userId])

	// GET TOTAL DRINKS
	useEffect(() => {
		const drinksArray = []

		for (const drinkLog of totalVisits) {
			for (const drink of drinkLog.drinks) {
				drinksArray.push(drink)
			}
		}
		setTotalDrinks(drinksArray)
	}, [totalVisits])

	// GET TOTAL ATTENDEES
	useEffect(() => {
		const attendeesArray = []

		for (const attendeeLog of totalVisits) {
			for (const attendee of attendeeLog.attendees) {
				attendeesArray.push(attendee)
			}
		}
		setTotalAttendees(attendeesArray)
	}, [totalVisits])

	// GET TOTAL ML
	useEffect(() => {
		let array = totalDrinks
		let ml = array.reduce((acc, item) => acc + item.ml, 0)
		setTotalMl(ml)
	}, [totalDrinks])

	// GET TOTAL UNITS
	useEffect(() => {
		let array = totalDrinks
		let units = array.reduce((acc, item) => acc + item.units, 0)
		units = Math.round((units + Number.EPSILON) * 10) / 10
		setTotalUnits(units)
	}, [totalDrinks])

	// GET TOTAL VENUES
	useEffect(() => {
		let array = new Set()
		for (const item of totalVisits) {
			if (item.location_id !== null) {
				array.add(item.location_id)
			}
		}
		setTotalVenues(array.size)
	}, [totalVisits])

	// TODAYS VISITS
	useEffect(() => {
		let totalVisitsToday = []
		if (totalVisits.length > 0) {
			totalVisits.forEach((item) => {
				if (
					[format(currentDate, "P")].includes(
						format(parseISO(item.timestamp), "P")
					)
				) {
					totalVisitsToday.push(item)
				}
			})
			setTodaysVisits(totalVisitsToday)
		}
	}, [totalVisits, currentDate])

	// TODAYS DRINKS
	useEffect(() => {
		let totalDrinksToday = []
		if (totalDrinks.length > 0) {
			totalDrinks.forEach((item) => {
				if (
					[format(currentDate, "P")].includes(
						format(parseISO(item.timestamp), "P")
					)
				) {
					totalDrinksToday.push(item)
				}
			})
			setTodaysDrinks(totalDrinksToday)
		}
	}, [totalDrinks, currentDate])

	// TODAYS ATTENDEES
	useEffect(() => {
		let totalAttendeesToday = []
		if (totalAttendees.length > 0) {
			totalAttendees.forEach((item) => {
				if (
					[format(currentDate, "P")].includes(
						format(parseISO(item.timestamp), "P")
					)
				) {
					totalAttendeesToday.push(item)
				}
			})
			// console.log(totalAttendeesToday)
			setTodaysAttendees(totalAttendeesToday)
		}
	}, [totalAttendees, currentDate])

	// GET TODAYS ML
	useEffect(() => {
		let array = todaysDrinks
		let ml = array.reduce((acc, item) => acc + item.ml, 0)
		setTodaysMl(ml)
	}, [todaysDrinks])

	// GET TODAYS UNITS
	useEffect(() => {
		let array = todaysDrinks
		let units = array.reduce((acc, item) => acc + item.units, 0)
		units = Math.round((units + Number.EPSILON) * 10) / 10
		setTodaysUnits(units)
	}, [todaysDrinks])

	// GET TODAYS VENUES
	useEffect(() => {
		let array = new Set()
		for (const item of todaysVisits) {
			if (item.location_id !== null) {
				array.add(item.location_id)
			}
		}
		setTodaysVenues(array.size)
	}, [todaysVisits])

	return (
		<>
			<DateNav
				currentDate={currentDate}
				setCurrentDate={setCurrentDate}
				today={today}
			/>

			<Stats
				amountDrank={todaysMl}
				attendedVenues={todaysVenues}
				metUpFriends={todaysAttendees}
				unitsConsumed={todaysUnits}
				currentDate={currentDate}
				comments={true}
				loading={loading}
			/>
			<Total />

			<Stats
				amountDrank={totalMl}
				attendedVenues={totalVenues}
				unitsConsumed={totalUnits}
				metUpFriends={totalAttendees}
				comments={false}
				loading={loading}
			/>
		</>
	)
}
