import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

export default function Socials({ website, instagram, twitter }) {
	return (
		<ul className="socials">
			{website && (
				<li>
					<a href={website} rel="noreferrer" target="_blank">
						<FontAwesomeIcon icon="fas fa-globe" />
					</a>
				</li>
			)}
			{instagram && (
				<li>
					<a
						href={`https://instagram.com/${instagram}`}
						rel="noreferrer"
						target="_blank">
						<FontAwesomeIcon icon="fa-brands fa-instagram" />
					</a>
				</li>
			)}
			{twitter && (
				<li>
					<a
						href={`https://twitter.com/${twitter}`}
						rel="noreferrer"
						target="_blank">
						<FontAwesomeIcon icon="fa-brands fa-twitter" />
					</a>
				</li>
			)}
		</ul>
	)
}
