import { Link } from "react-router-dom"
import toTime from "../../helpers/getTime"
import AvatarIcon from "../AvatarIcon"
import Drink from "./Drink"
import * as ROUTES from "../../constants/routes"

export default function Visit({ visit }) {
	let attendees = []
	let drinks = []
	let location

	if (visit) {
		attendees = visit.attendees
		drinks = visit.drinks
		location = visit.location
	}
	return (
		<div className="visit" key={visit.visit_id}>
			{location && (
				<div className="location">
					<Link to={`${ROUTES.LOCATION}/${location.id}`}>
						<span className="name">{location.name}</span>
					</Link>
					{" - "}
					<span className="date">{toTime(visit.timestamp)}</span>
				</div>
			)}
			{attendees.length > 0 && (
				<>
					<small>with:</small>
					<div className="attendees">
						{attendees.map(
							(attendee) =>
								attendee.avatar && (
									<Link
										key={attendee.id}
										to={`${ROUTES.PROFILE}/${attendee.username}`}>
										<AvatarIcon size={40} url={attendee.avatar} />
									</Link>
								)
						)}
					</div>
				</>
			)}
			{drinks.length > 0 && (
				<ul className="drinks">
					{visit.drinks.map((drink) => (
						<Drink key={drink.id} drink={drink} />
					))}
				</ul>
			)}
			<div className="edit">
				<span>Edit</span>
			</div>
		</div>
	)
}
