import { CURRENT_MODE_STORAGE_KEY } from "../constants/paths"

const ToggleSwitch = ({ isWine, setIsWine }) => {
	const handleToggle = () => {
		const newIsWine = !isWine
		localStorage.setItem(CURRENT_MODE_STORAGE_KEY, JSON.stringify(newIsWine))
		setIsWine(newIsWine)
	}

	return (
		<div className="toggle-switch">
			<div className="title">Choose your poison:</div>
			<div className="container">
				<div className="icon --beer" />
				<div className="switch">
					<input
						type="checkbox"
						id="switch"
						checked={isWine}
						onChange={handleToggle}
					/>
					<label htmlFor="switch">Toggle</label>
				</div>
				<div className="icon --wine" />
			</div>
		</div>
	)
}

export default ToggleSwitch
