import React, { useEffect, useState } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { RECENT_SEARCHES_STORAGE_KEY } from "../../constants/paths"
import * as ROUTES from "../../constants/routes"
import { supabase } from "../../lib/supabaseClient"
import executeQuery from "../../helpers/executeQuery"
import ResultUser from "../../components/explore/ResultUser"

export default function ResultsProfilesPage() {
	const navigate = useNavigate()
	const { query, storedSearches } = useOutletContext()

	// GET CURRENT USER ID
	const [currentUserId, setCurrentUserId] = useState(null)

	useEffect(() => {
		const fetchCurrentUserId = async () => {
			try {
				const { data } = await supabase.auth.getUser()
				setCurrentUserId(data.user.id)
			} catch (error) {
				console.error(error.message)
			}
		}

		if (currentUserId === null) {
			fetchCurrentUserId()
		}
	}, [currentUserId])

	// RECENT USERS
	const [recentUsers, setRecentUsers] = useState()

	useEffect(() => {
		if (storedSearches && storedSearches.recent_profiles) {
			setRecentUsers(storedSearches.recent_profiles)
		} else {
			setRecentUsers([])
		}
	}, [storedSearches])

	useEffect(() => {
		if (recentUsers) {
			let updatedStoredSearches = {
				...storedSearches,
				recent_profiles: recentUsers,
			}
			localStorage.setItem(
				RECENT_SEARCHES_STORAGE_KEY,
				JSON.stringify(updatedStoredSearches)
			)
		}
	}, [storedSearches, recentUsers])

	async function handleOnClick(user) {
		let filteredRecentUsers = [...recentUsers]
		filteredRecentUsers = filteredRecentUsers.filter(
			(item) => item.user_id !== user.user_id
		)
		if (filteredRecentUsers.length > 4) {
			filteredRecentUsers.pop()
		}

		await setRecentUsers([...filteredRecentUsers, user])
		await navigate(`${ROUTES.PROFILE}/${user.username}`)
	}

	// SEARCH RESULTS
	const [searchResults, setSearchResults] = useState([])

	useEffect(() => {
		if (query.trim() === "") {
			setSearchResults([])
			return
		}
		handleSearch()
		// eslint-disable-next-line
	}, [query])

	const handleSearch = async () => {
		const { data, loading } = await executeQuery(
			supabase
				.from("profiles")
				.select("username, user_id, f_name, l_name, friends, avatar")
				.ilike("username, f_name, l_name", `%${query}%`)
				.neq("user_id", currentUserId)
		)
		setSearchResults(data)
	}

	return (
		<>
			{query && query.length > 0 ? (
				<>
					<h3>Results</h3>
					<ul className="search-results">
						{searchResults.map((user) => (
							<ResultUser
								key={user.user_id}
								user={user}
								handleOnClick={handleOnClick}
							/>
						))}
					</ul>
				</>
			) : (
				<>
					<h3>Recent User Searches</h3>
					<ul className="search-results reversed">
						{recentUsers &&
							recentUsers.length > 0 &&
							recentUsers.map((user) => (
								<ResultUser
									key={user.user_id}
									user={user}
									handleOnClick={handleOnClick}
								/>
							))}
					</ul>
				</>
			)}
		</>
	)
}
