import React, { useEffect } from "react"
import { useLoaderData } from "react-router-dom"
import { APP_NAME } from "../.."
import DrinkNav from "../../components/profile/DrinkNav"

export default function DrinkPage() {
	const drink = useLoaderData()

	useEffect(() => {
		document.title = `${drink.name} • ${APP_NAME}`
	}, [drink])

	return (
		<div className="profile-drink main-content">
			<div className="hero">
				<DrinkNav />
			</div>
			<div className="content">
				<div className="container">
					<h1 className="title">{drink.name}</h1>
					{drink.countries?.name_en && (
						<div className="origin">
							<div className="originW">{drink.countries.name_en}</div>
						</div>
					)}

					<div className="description">
						<div className="type">
							<span className="label">Type: </span> {drink.drink_types.name}
						</div>
						<div className="percentage">
							<span className="label">Alcohol: </span>{" "}
							{drink.alcohol_percentage}%
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
