import { Outlet, useNavigate, useNavigation } from "react-router-dom"
import FooterNav from "../components/FooterNav"
import { CURRENT_MODE_STORAGE_KEY } from "../constants/paths"
import { useEffect, useState } from "react"
import Spinner from "../components/Spinner"
import { useSession } from "../context/SessionContext"
import * as ROUTES from "../constants/routes"
import executeQuery from "../helpers/executeQuery"
import { supabase } from "../lib/supabaseClient"

export default function Root() {
	const navigate = useNavigate()
	const navigation = useNavigation()
	const { session } = useSession()
	const [user, setUser] = useState(null)

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		let userId = session?.user.id
		async function fetchUser() {
			try {
				setLoading(true)
				const { data } = await executeQuery(
					supabase
						.from("profiles")
						.select("avatar, f_name, l_name, username, user_id, email")
						.eq("user_id", userId)
						.single()
				)
				setUser(data)
				setLoading(false)
			} catch (error) {
				console.error("Error fetching data:", error)
				setLoading(false)
			}
		}

		if (!user && userId) {
			fetchUser()
		}
	}, [user, session])

	useEffect(() => {
		if (!session) {
			const timerId = setTimeout(() => navigate(ROUTES.LOGIN), 200) // Redirect to login after 2 seconds
			return () => clearTimeout(timerId) // Clear timer on unmount
		}
	}, [session, navigate])

	// TOGGLE WINE MODE
	const storedIsWine = localStorage.getItem(CURRENT_MODE_STORAGE_KEY)
	const initialIsWine = storedIsWine ? JSON.parse(storedIsWine) : false
	const [isWine, setIsWine] = useState(initialIsWine)

	useEffect(() => {
		localStorage.setItem(CURRENT_MODE_STORAGE_KEY, JSON.stringify(isWine))
		document.body.classList.toggle("wine-mode", isWine)

		return () => {
			document.body.classList.remove("wine-mode")
		}
	}, [isWine])

	return (
		<div className={`site-container`}>
			<>
				<div className="main-content">
					{navigation.state === "loading" ? (
						<Spinner />
					) : (
						<Outlet context={{ user, setIsWine, isWine, loading }} />
					)}
				</div>
				<FooterNav user={user} />
			</>
		</div>
	)
}
