import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { APP_NAME } from "../.."
import Logo from "../../components/Logo"
import { useSession } from "../../context/SessionContext"
import * as ROUTES from "../../constants/routes"

const LandingPage = () => {
	const navigate = useNavigate()
	const { session } = useSession()

	useEffect(() => {
		if (!session) {
			const timerId = setTimeout(() => navigate(ROUTES.LOGIN), 2000) // Redirect to login after 2 seconds
			return () => clearTimeout(timerId) // Clear timer on unmount
		} else {
			navigate(ROUTES.DASHBOARD) // Redirect to dashboard if already logged in
		}
	}, [session, navigate])

	useEffect(() => {
		document.title = `${APP_NAME}`
	}, [])

	return (
		<div className="landing-page">
			<div className="container">
				<header>
					<div className="logoW">
						<Logo />
					</div>
					<h1>
						<span>Pints of Nice</span>
					</h1>
				</header>

				<footer>
					<p>
						<small>
							&copy; 2024 {APP_NAME}
							<br /> All rights reserved
						</small>
					</p>
				</footer>
			</div>
		</div>
	)
}

export default LandingPage
