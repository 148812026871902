import React, { useEffect } from "react"
import { APP_NAME } from "../.."
import AvatarIcon from "../../components/AvatarIcon"
import ProfileNav from "../../components/profile/ProfileNav"
import { Outlet, useOutletContext } from "react-router-dom"

export default function MeLayout() {
	const data = useOutletContext()
	let user = data?.user
	let loading = data?.loading
	let fullname = user ? `${user.f_name} ${user.l_name}` : "..."

	useEffect(() => {
		document.title = `@${user?.username} • ${APP_NAME}`
	}, [user])

	return (
		<>
			<ProfileNav user={user} />
			<div className="profile-page main-content">
				<div className="container">
					<header>
						<AvatarIcon url={user?.avatar} size={100} loading={loading} />
						<div className="name">{fullname}</div>
					</header>
					<main>
						<Outlet context={user} />
					</main>
				</div>
			</div>
		</>
	)
}
