import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import AvatarIcon from "./AvatarIcon"

export default function ResultUser({ user, handleOnClick }) {
	return (
		<li onClick={() => handleOnClick(user)}>
			{user && (
				<div className="result" data-type="user">
					{user.avatar ? (
						<AvatarIcon url={user.avatar} size={60} />
					) : (
						<div className="icon">
							<FontAwesomeIcon icon={`fas fa-user`} />
						</div>
					)}
					<div className="text">
						<div className="username">{user.username}</div>
						<div className="name">
							{user.f_name} {user.l_name}
						</div>
						{user.friends && user.friends.length > 0 && (
							<div className="followedby">
								Friends with {user.friends[0]}{" "}
								{user.friends.length > 1 && `+ ${user.friends.length - 1} more`}
							</div>
						)}
					</div>
				</div>
			)}
		</li>
	)
}
