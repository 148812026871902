import { addDays, format, isToday, isYesterday, subDays } from "date-fns"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const DateNav = ({ today, currentDate, setCurrentDate }) => {
	function handlePrevDayButton() {
		let day = currentDate
		let prevDay = subDays(day, 1)
		setCurrentDate(prevDay)
	}

	function handleNextDayButton() {
		if (isToday(currentDate)) return
		let day = currentDate
		let nextDay = addDays(day, 1)
		setCurrentDate(nextDay)
	}

	function handleOnKeyDown(e) {
		// Left
		if (e.which === 37) {
			handlePrevDayButton()
		}
		// Right
		if (e.which === 39) {
			handleNextDayButton()
		}
	}
	window.onkeydown = (e) => handleOnKeyDown(e)

	function handleResetButton() {
		if (isToday(currentDate)) return
		setCurrentDate(today)
	}

	function handleDateFormat(date) {
		if (isToday(date)) {
			return "Today"
		} else if (isYesterday(date)) {
			return "Yesterday"
		} else {
			return format(date, "E, d MMM")
		}
	}

	return (
		<nav className="dateNav">
			<div className="prev" onClick={() => handlePrevDayButton()}>
				<FontAwesomeIcon icon={`fas fa-arrow-left`} />
			</div>
			<div className="date" onClick={() => handleResetButton()}>
				{handleDateFormat(currentDate)}
			</div>
			<div
				className={`next${isToday(currentDate) ? " hide" : ""}`}
				onClick={() => handleNextDayButton()}>
				<FontAwesomeIcon icon={`fas fa-arrow-right`} />
			</div>
		</nav>
	)
}

export default DateNav
