import React, { useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"
import { APP_NAME } from "../.."
import Actions from "../../components/dashboard/Actions"
import BeersIcon from "../../components/profile/Beers"
import WinesIcon from "../../components/profile/Wines"
import * as ROUTES from "../../constants/routes"
import SiteNav from "../../components/dashboard/SiteNav"
import { isPast, parseISO } from "date-fns"
import { useSession } from "../../context/SessionContext"
import executeQuery from "../../helpers/executeQuery"
import { supabase } from "../../lib/supabaseClient"
import Spinner from "../../components/Spinner"

export default function DashboardPage() {
	const { user, setIsWine, isWine } = useOutletContext()
	const { session } = useSession()
	let userId = session?.user.id

	const [loading, setLoading] = useState(true)
	const [currentVisit, setCurrentVisit] = useState(null)
	const [attendees, setAttendees] = useState([])
	const [drinks, setDrinks] = useState([])
	const [totalMl, setTotalMl] = useState(0)

	// GET VISIT
	useEffect(() => {
		const fetchCurrentVisit = async () => {
			setLoading(true) // Set loading to true when starting to fetch data
			if (userId) {
				try {
					const { data } = await executeQuery(
						supabase
							.from("profiles")
							.select(
								"user_visits(id, expires, locations(name), user_visit_attendees(id), user_visit_drinks(ml))"
							)
							.eq("user_id", userId)
							.single()
					)

					if (data) {
						let user_visits = data.user_visits
						const givenDate = parseISO(user_visits.expires)
						const hasPassed = isPast(givenDate)
						user_visits = hasPassed ? null : user_visits
						setCurrentVisit(user_visits)
					}
				} catch (error) {
					console.error(error)
				} finally {
					setLoading(false) // Set loading to false when data is fetched (regardless of success or error)
				}
			} else {
				setCurrentVisit(null)
			}
		}

		if (currentVisit === null) {
			fetchCurrentVisit()
		}
	}, [currentVisit, userId])

	useEffect(() => {
		if (currentVisit) {
			setAttendees(currentVisit.user_visit_attendees)
			setDrinks(currentVisit.user_visit_drinks)
		}
	}, [currentVisit])

	useEffect(() => {
		if (drinks?.length > 0) {
			setTotalMl(drinks.reduce((acc, item) => acc + item.ml, 0))
		}
	}, [drinks])

	useEffect(() => {
		document.title = `Dashboard • ${APP_NAME}`
	}, [])

	return (
		<div className="page-dashboard">
			<SiteNav user={user} isWine={isWine} setIsWine={setIsWine} />
			<div className="container">
				{loading ? (
					<Spinner />
				) : (
					<main>
						<div>
							{!isWine ? (
								<BeersIcon
									comments={true}
									title={currentVisit?.locations?.name}
									ml={totalMl}
								/>
							) : (
								<WinesIcon
									comments={true}
									title={currentVisit?.locations?.name}
									ml={totalMl}
								/>
							)}
							{currentVisit && (
								<Actions
									icon={`${!isWine ? "beer" : "wine-bottle"}`}
									title={
										drinks && drinks.length > 0
											? "Had another?"
											: `Had a ${!isWine ? "beer" : "wine"}?`
									}
									description="Log it in!"
									link={isWine ? ROUTES.ADD_WINE : ROUTES.ADD_DRINK}
								/>
							)}
							{currentVisit && (
								<Actions
									icon={
										attendees && attendees.length > 1 ? "user-friends" : "user"
									}
									title={
										attendees && attendees.length > 0
											? `Currently with ${attendees.length} friend${
													attendees.length === 1 ? "" : "s"
											  }`
											: "Drinking alone?"
									}
									description={
										attendees && attendees.length > 0
											? "Has anyone else joined?"
											: "Add attendees"
									}
									link={ROUTES.ADD_ATTENDEES}
								/>
							)}
							<Actions
								icon={currentVisit ? `shop` : "hand-point-right"}
								title={currentVisit ? "Moved on?" : "Starting a session?"}
								description={
									currentVisit ? "Update your location" : "Add your location."
								}
								link={ROUTES.ADD_LOCATION}
							/>
						</div>
					</main>
				)}
			</div>
		</div>
	)
}
