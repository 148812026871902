import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import AvatarIcon from "../../components/AvatarIcon"
import ResultUser from "../../components/ResultUser"
import { supabase } from "../../lib/supabaseClient"
import * as ROUTES from "../../constants/routes"
import executeQuery from "../../helpers/executeQuery"
import { useSession } from "../../context/SessionContext"
import { isPast, parseISO } from "date-fns"

export default function AddProfilesPage() {
	const navigate = useNavigate()
	const { session } = useSession()
	let userId = session?.user.id

	const [currentVisit, setCurrentVisit] = useState(null)

	// GET VISIT
	useEffect(() => {
		const fetchCurrentVisit = async () => {
			if (userId) {
				const { data, loading } = await executeQuery(
					supabase
						.from("profiles")
						.select(
							"user_visits(id, user_visit_attendees(id, profiles(f_name, l_name, username, avatar, user_id)))"
						)
						.eq("user_id", userId)
						.single()
				)
				if (data) {
					let user_visits = data.user_visits
					if (user_visits) {
						const givenDate = parseISO(user_visits.expires)
						const hasPassed = isPast(givenDate)
						user_visits = hasPassed ? null : user_visits
						setCurrentVisit(user_visits)
					}
				}
			} else {
				setCurrentVisit(null)
			}
		}

		if (currentVisit === null) {
			fetchCurrentVisit()
		}
	}, [currentVisit, userId])

	// Handle search
	const [query, setQuery] = useState("")
	const [searchResults, setSearchResults] = useState([])
	const [selectedAttendees, setSelectedAttendees] = useState([])
	const [selectedAttendeesIds, setSelectedAttendeesIds] = useState([])

	useEffect(() => {
		let attendees = currentVisit
			? currentVisit.user_visit_attendees.map((item) => item.profiles)
			: []
		let attendeesIds = []
		if (attendees.length > 0) {
			attendeesIds = attendees.map((item) => item.user_id)
		}
		setSelectedAttendees(attendees)
		setSelectedAttendeesIds(attendeesIds)
	}, [currentVisit])

	useEffect(() => {
		if (query.trim() === "") {
			setSearchResults([])
			return
		}
		handleSearch()
		// eslint-disable-next-line
	}, [query])

	const handleSearch = async () => {
		const { data, loading } = await executeQuery(
			supabase
				.from("profiles")
				.select(`username, avatar, f_name, l_name, user_id`)
				.or(
					`username.ilike.%${query}%,f_name.ilike.%${query}%,l_name.ilike.%${query}%`
				)
				.neq("user_id", userId)
				.not("user_id", "in", `(${selectedAttendeesIds})`)
				.limit(5)
		)
		setSearchResults(data)
	}

	function handleOnClick(result) {
		let updatedSelectedAttendees = [...selectedAttendees, result]
		updatedSelectedAttendees = [...new Set(updatedSelectedAttendees)]
		let updatedSelectedAttendeesIds = [...selectedAttendeesIds, result.user_id]
		updatedSelectedAttendeesIds = [...new Set(updatedSelectedAttendeesIds)]
		setQuery("")
		setSelectedAttendees(updatedSelectedAttendees)
		setSelectedAttendeesIds(updatedSelectedAttendeesIds)
		setSearchResults([])
	}

	function handleOnChange(e) {
		setQuery(e.target.value)
	}

	async function removeOldAttendees(visit_id) {
		const { data, loading } = await executeQuery(
			supabase
				.from("user_visit_attendees")
				.delete()
				.eq("user_visit_id", visit_id)
		)
		return data
	}

	async function addNewAttendees(visit_id, attendees) {
		let arr = []
		if (attendees.length > 0) {
			arr = attendees.map((item) => ({
				user_id: item,
				user_visit_id: visit_id,
			}))
		}
		const { data, loading } = await executeQuery(
			await supabase.from("user_visit_attendees").insert(arr).select()
		)
		return data
	}

	// update session
	async function updateVisit(visit_id, attendees) {
		// 1. Remove old attendee visits
		await removeOldAttendees(visit_id)

		// 2. Add new attendee visits
		await addNewAttendees(visit_id, attendees)
	}

	async function handleOnSubmit(e) {
		e.preventDefault()

		try {
			if (!currentVisit) {
				throw new Error("Error")
			}
			await updateVisit(currentVisit.id, selectedAttendeesIds)
			navigate(ROUTES.DASHBOARD)
		} catch (error) {
			console.error(error.message)
		}
	}

	function handleReturn() {
		navigate(ROUTES.DASHBOARD)
		// navigate(-1)
	}

	function removeSelectedAttendee(id) {
		setSelectedAttendees(selectedAttendees.filter((a) => a.user_id !== id))
		setSelectedAttendeesIds(selectedAttendeesIds.filter((a) => a !== id))
	}

	return (
		<>
			<div className="page-dashboard">
				<div className="container">
					<main>
						<div>
							<p>
								<FontAwesomeIcon
									icon={`arrow-left`}
									style={{ cursor: "pointer" }}
									onClick={() => handleReturn()}
								/>
							</p>
							<h3>Are other users there?</h3>
							<form method="post" onSubmit={handleOnSubmit}>
								<fieldset>
									<div style={{ marginBottom: 10 }}>Selected attendees:</div>
									<div className="selected-attendees">
										{selectedAttendees.map((selectedAttendee) => (
											<div
												className={`selected-attendee`}
												key={selectedAttendee.user_id}>
												<button
													className="remove"
													onClick={() =>
														removeSelectedAttendee(selectedAttendee.user_id)
													}>
													<FontAwesomeIcon icon={`times`} />
												</button>
												<AvatarIcon url={selectedAttendee.avatar} size={50} />
											</div>
										))}
									</div>
									<div className="search-bar">
										<button>
											<FontAwesomeIcon icon={`fas fa-search`} />
										</button>
										<input
											autoComplete="off"
											className="input"
											placeholder={``}
											aria-label={`placeholder`}
											type="text"
											name="attendee"
											value={query || ""}
											onChange={handleOnChange}
										/>
										<button
											onClick={(e) => {
												e.preventDefault()
												setQuery("")
											}}>
											<FontAwesomeIcon icon={`times`} />
										</button>
									</div>
									{searchResults &&
										searchResults.length > 0 &&
										query.length > 0 && (
											<div className="search-results">
												<ul>
													{searchResults.map((result) => (
														<ResultUser
															key={result.user_id}
															user={result}
															handleOnClick={handleOnClick}
														/>
													))}
												</ul>
											</div>
										)}
								</fieldset>
								<fieldset>
									<button className="button">
										{selectedAttendees.length > 0 ? "Submit" : "No"}
									</button>
								</fieldset>
							</form>
						</div>
					</main>
				</div>
			</div>
		</>
	)
}
