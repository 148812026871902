import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { supabase } from "../../lib/supabaseClient"
import * as ROUTES from "../../constants/routes"
import executeQuery from "../../helpers/executeQuery"
import { isPast, parseISO } from "date-fns"
import { useSession } from "../../context/SessionContext"

export default function AddWinePage() {
	const navigate = useNavigate()
	const { session } = useSession()
	let userId = session?.user.id
	const [currentVisit, setCurrentVisit] = useState(null)

	// GET VISIT
	useEffect(() => {
		const fetchCurrentVisit = async () => {
			if (userId) {
				const { data, loading } = await executeQuery(
					supabase
						.from("profiles")
						.select("current_visit_id, user_visits(expires)")
						.eq("user_id", userId)
						.single()
				)
				if (data) {
					let user_visits = data.user_visits
					let current_visit_id = data.current_visit_id
					if (user_visits) {
						const givenDate = parseISO(user_visits.expires)
						const hasPassed = isPast(givenDate)
						user_visits = hasPassed ? null : user_visits
						setCurrentVisit(current_visit_id)
					}
				}
			} else {
				setCurrentVisit(null)
			}
		}

		if (currentVisit === null) {
			fetchCurrentVisit()
		}
	}, [currentVisit, userId])

	// Handle search
	const [query, setQuery] = useState("")
	const [queryError, setQueryError] = useState(false)
	const [searchResults, setSearchResults] = useState([])
	const [selectedDrink, setSelectedDrink] = useState(null)

	// Quick add
	const [quantity, setQuantity] = useState(0)
	const [quantityError, setQuantityError] = useState(false)

	useEffect(() => {
		const handleSearch = async () => {
			const { data, loading } = await executeQuery(
				supabase
					.from("drinks")
					.select("name, id")
					.or(`name.ilike.%${query}%`)
					.eq("type", "9")
					.order("name", { ascending: true })
					.limit(5)
			)
			setSearchResults(data)
		}

		if (query.trim() === "") {
			setSearchResults([])
			return
		}
		handleSearch()
	}, [query])

	function handleOnChange(e) {
		setQuery(e.target.value)
	}

	async function insertUserVisitDrink(drink) {
		const { data, loading } = await executeQuery(
			supabase.from("user_visit_drinks").insert([drink]).select()
		)
		return data
	}

	async function handleOnSubmit(e) {
		e.preventDefault()

		try {
			if (!selectedDrink) {
				setQueryError(true)
				throw new Error("Please select a type of wine")
			}
			if (!quantity > 0) {
				setQuantityError(true)
				throw new Error("Please select an amount")
			}
			if (!currentVisit) {
				throw new Error("No current visit")
			}

			let newUserVisitDrink = {
				user_visit_id: currentVisit,
				drink_id: selectedDrink.id,
				ml: quantity,
			}
			await insertUserVisitDrink(newUserVisitDrink)
			navigate(ROUTES.DASHBOARD)
		} catch (error) {
			console.error(error.message)
		}
	}

	const QuickAdd = ({ icon, title, ml }) => {
		return (
			<div
				className="quick-add"
				onClick={() => {
					setQuantityError(false)
					setQuantity(ml)
				}}>
				{icon && (
					<div className="icon">
						<FontAwesomeIcon icon={`fas fa-${icon}`} />
					</div>
				)}
				{title && <div className="title">{title}</div>}
				{ml && <div className="amount">{`(${ml} ml)`}</div>}
			</div>
		)
	}

	function handleReturn() {
		navigate(ROUTES.DASHBOARD)
	}

	return (
		<div className="page-dashboard main-content">
			<div className="container">
				<main>
					<div>
						<p>
							<FontAwesomeIcon
								icon={`arrow-left`}
								style={{ cursor: "pointer" }}
								onClick={() => handleReturn()}
							/>
						</p>
						<h3>What are you drinking?</h3>
						<form method="post" onSubmit={handleOnSubmit} className="form">
							<fieldset>
								<label htmlFor="drink">Your type of wine:</label>
								<div className="search-bar">
									<button>
										<FontAwesomeIcon
											icon={`fas fa-${selectedDrink ? "check" : "search"}`}
										/>
									</button>
									<input
										id="drink"
										autoComplete="off"
										className="input"
										placeholder={`_`}
										type="text"
										name="drink"
										value={query || ""}
										onChange={handleOnChange}
									/>
									<button
										onClick={(e) => {
											e.preventDefault()
											setQuery("")
											setSelectedDrink(null)
										}}>
										<FontAwesomeIcon icon={`times`} />
									</button>
								</div>
								{queryError && <p className="error">Please chose a drink</p>}
								{!selectedDrink && (
									<div className="search-results">
										<ul>
											{searchResults.map((result) => (
												<li
													key={result.id}
													onClick={() => {
														setQuery(result.name)
														setSelectedDrink(result)
														setSearchResults([])
														setQueryError(false)
													}}>
													<div>{result.name}</div>
													<div>
														<small>{result.address}</small>
													</div>
												</li>
											))}
										</ul>
									</div>
								)}
								<input
									name="drink-id"
									value={selectedDrink || ""}
									onChange={(e) => setSelectedDrink(e.target.value)}
									style={{ display: "none" }}
								/>
								<p style={{ textAlign: "center" }}>
									<small>
										<Link to={`../new-drink`}>
											Not in the list? <strong>Add it!</strong>
										</Link>
									</small>
								</p>
							</fieldset>

							<fieldset>
								<label htmlFor="quantity">How big is your drink?</label>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										gap: "10px",
									}}>
									<input
										id="quantity"
										type="number"
										placeholder="0"
										min={0}
										value={quantity || ""}
										onChange={(e) => {
											setQuantityError(false)
											setQuantity(e.target.value)
										}}
									/>
									<div>
										<b>ml</b>
									</div>
								</div>
								{quantityError && <p className="error">Please add quantity</p>}
							</fieldset>

							<fieldset>
								<p>Quick adds:</p>
								<div className="quick-adds">
									<QuickAdd icon="wine-glass" title="small" ml={125} />
									<QuickAdd icon="wine-glass" title="medium" ml={175} />
									<QuickAdd icon="wine-glass" title="large" ml={250} />
								</div>
							</fieldset>

							<fieldset>
								<button className="button">Submit</button>
							</fieldset>
						</form>
					</div>
				</main>
			</div>
		</div>
	)
}
