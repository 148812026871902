import React, { useEffect, useState } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { RECENT_SEARCHES_STORAGE_KEY } from "../../constants/paths"
import * as ROUTES from "../../constants/routes"
import { supabase } from "../../lib/supabaseClient"
import executeQuery from "../../helpers/executeQuery"
import ResultPlace from "../../components/explore/ResultPlace"

export default function ResultsLocationsPage() {
	const navigate = useNavigate()
	const { query, storedSearches } = useOutletContext()

	const [recentPlaces, setRecentPlaces] = useState()
	const [searchResults, setSearchResults] = useState([])

	useEffect(() => {
		if (query.trim() === "") {
			setSearchResults([])
			return
		}
		handleSearch()
		// eslint-disable-next-line
	}, [query])

	const handleSearch = async () => {
		// Make a query to Supabase to search for data based on the searchTerm
		const { data, loading } = await executeQuery(
			supabase
				.from("locations")
				.select(`name, id, address, type, location_types ( name )	`)
				.ilike("name", `%${query}%`)
				.neq("id", 25)
		)
		setSearchResults(data)
	}

	useEffect(() => {
		setRecentPlaces(storedSearches?.recent_places || [])
	}, [storedSearches])

	useEffect(() => {
		if (recentPlaces) {
			let updatedStoredSearches = {
				...storedSearches,
				recent_places: recentPlaces,
			}
			localStorage.setItem(
				RECENT_SEARCHES_STORAGE_KEY,
				JSON.stringify(updatedStoredSearches)
			)
		}
	}, [storedSearches, recentPlaces])

	async function handleOnClick(place) {
		try {
			const filteredRecentPlaces = recentPlaces.filter(
				(item) => item.id !== place.id
			)

			if (filteredRecentPlaces.length > 4) {
				filteredRecentPlaces.pop()
			}

			await setRecentPlaces([...filteredRecentPlaces, place])
			await navigate(`${ROUTES.LOCATION}/${place.id}`)
		} catch (error) {
			console.error(error.message)
		}
	}

	return (
		<>
			{query && query.length > 0 ? (
				<>
					<h3>Results</h3>
					<ul className="search-results">
						{searchResults.map((place) => (
							<ResultPlace
								key={place.id}
								place={place}
								handleOnClick={handleOnClick}
							/>
						))}
					</ul>
				</>
			) : (
				<>
					<h3>Recent Location Searches</h3>
					<ul className="search-results reversed">
						{recentPlaces &&
							recentPlaces.length > 0 &&
							recentPlaces.map((place) => (
								<ResultPlace
									key={place.id}
									place={place}
									handleOnClick={handleOnClick}
								/>
							))}
					</ul>
				</>
			)}
		</>
	)
}
