import React, { useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NavLink, useMatch } from "react-router-dom"

const ExploreNav = ({ query, setQuery }) => {
	const focusRef = useRef()
	const match1 = useMatch("/explore/locations")
	const match2 = useMatch("/explore/drinks")
	let m = match1 ? 1 : match2 ? 2 : 0
	return (
		<>
			<div className="explore-nav">
				<div>
					<div className="search-bar">
						<button>
							<FontAwesomeIcon icon={`fas fa-search`} />
						</button>
						<input
							autoComplete="off"
							ref={focusRef}
							className="input"
							placeholder="Search"
							aria-label="search"
							type="text"
							name="search-form"
							value={query || ""}
							onChange={(e) => setQuery(e.target.value)}
						/>
						<button onClick={() => setQuery("")}>
							<FontAwesomeIcon icon={`times`} />
						</button>
					</div>
					<nav className="explore-tabs">
						<ul>
							<li>
								<NavLink to={"./profiles"}>Users</NavLink>
							</li>
							<li>
								<NavLink to={"./locations"}>Locations</NavLink>
							</li>
							<li>
								<NavLink to={"./drinks"}>Drinks</NavLink>
							</li>
							<li className="line" style={{ left: `${33.33 * m}%` }} />
						</ul>
					</nav>
				</div>
			</div>
		</>
	)
}

export default ExploreNav
