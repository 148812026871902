import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"

const LocationNav = ({ location }) => {
	const navigate = useNavigate()
	function handleCancel() {
		navigate(-1)
	}

	return (
		<>
			<div className="location-nav">
				<div className="cancel" onClick={(e) => handleCancel(e)}>
					<FontAwesomeIcon icon={`fas fa-arrow-left`} />
				</div>
				<div className="menu"></div>
			</div>
		</>
	)
}

export default LocationNav
