import React, { useEffect } from "react"
import { APP_NAME } from "../.."
import Logo from "../../components/Logo"
import { supabase } from "../../lib/supabaseClient"
import { Auth } from "@supabase/auth-ui-react"
import { useNavigate } from "react-router-dom"
import { useSession } from "../../context/SessionContext"
import * as ROUTES from "../../constants/routes"

export default function LoginPage() {
	const navigate = useNavigate()
	const { session } = useSession()

	useEffect(() => {
		if (!session) {
			const timerId = setTimeout(() => navigate(ROUTES.LOGIN), 500) // Redirect to login after 5 seconds
			return () => clearTimeout(timerId) // Clear timer on unmount
		} else {
			navigate(ROUTES.DASHBOARD) // Redirect to dashboard if already logged in
		}
	}, [session, navigate])

	useEffect(() => {
		document.title = `Login • ${APP_NAME}`
	}, [])

	return (
		<div className="auth-page main-content">
			<div className="container">
				<Logo />
				<Auth
					supabaseClient={supabase}
					appearance={{
						extend: false,
						className: {
							anchor: "anchor",
							button: "button",
							//..
						},
					}}
					providers={false}
				/>
			</div>
		</div>
	)
}
