import React from "react"

const Spinner = ({ fullPage = true, white = false }) => {
	return (
		<div className={fullPage ? "spinnerW" : ""}>
			<div className={`dot-spinner${white ? " white" : ""}`}>
				<div className="dot-spinner__dot" />
				<div className="dot-spinner__dot" />
				<div className="dot-spinner__dot" />
				<div className="dot-spinner__dot" />
				<div className="dot-spinner__dot" />
				<div className="dot-spinner__dot" />
				<div className="dot-spinner__dot" />
				<div className="dot-spinner__dot" />
			</div>
		</div>
	)
}

export default Spinner
