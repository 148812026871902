import React, { useEffect, useState } from "react"
import { Outlet, useParams } from "react-router-dom"
import { APP_NAME } from "../.."
import AvatarIcon from "../../components/AvatarIcon"
import Total from "../../components/profile/Total"
import UserProfileNav from "../../components/profile/UserProfileNav"
import { supabase } from "../../lib/supabaseClient"
import executeQuery from "../../helpers/executeQuery"

export default function ProfileLayout() {
	const { username } = useParams()

	const [loading, setLoading] = useState(true)
	const [user, setUser] = useState(null)

	// GET USER
	useEffect(() => {
		async function fetchUser() {
			if (username) {
				try {
					setLoading(true)

					const user = await executeQuery(
						supabase
							.from("profiles")
							.select("avatar, f_name, l_name, username, user_id")
							.eq("username", username)
							.single()
					)
					setUser(user ? user.data : null)
					setLoading(false)
				} catch (error) {
					console.error("Error fetching data:", error)
					setLoading(false)
				}
			} else {
				setUser(null)
			}
		}

		if (user === null) {
			fetchUser()
		}
	}, [user, username])

	useEffect(() => {
		document.title = `@${username} • ${APP_NAME}`
	}, [username])

	return (
		<>
			<UserProfileNav user={user} />

			<div className="profile-page main-content">
				<div className="container">
					<header>
						<AvatarIcon url={user?.avatar} size={100} loading={loading} />
						<div className="name">
							{loading ? " " : `${user?.f_name} ${user?.l_name}`}
						</div>
					</header>
					<main>
						<Total />
						{user && <Outlet context={user.user_id} />}
					</main>
				</div>
			</div>
		</>
	)
}
