import { Link } from "react-router-dom"
import toTime from "../../helpers/getTime"
import * as ROUTES from "../../constants/routes"

export default function Drink({ drink }) {
	return (
		<li className="drink" key={drink.id}>
			{drink.timestamp && (
				<span className="time">{toTime(drink.timestamp)}</span>
			)}
			{"•"}

			<span className="title">
				<Link to={`${ROUTES.DRINK}/${drink?.id}`}>{drink?.name}</Link>
			</span>

			{"•"}
			<span className="description">{drink.ml}ml</span>

			{"•"}
			<span className="description">{drink.units} units</span>
		</li>
	)
}
