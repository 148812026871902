import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	addDays,
	format,
	getTime,
	isToday,
	isYesterday,
	subDays,
} from "date-fns"
import React, { useEffect, useState } from "react"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { APP_NAME } from "../.."
import HistoryNav from "../../components/profile/HistoryNav"

export default function HistoryPage() {
	const navigate = useNavigate()
	const param = useParams()

	// GET TODAY
	let today = new Date()
	today = today.getTime()

	let paramDate
	if ("y" in param && "m" in param && "d" in param) {
		paramDate = getTime(new Date(param.y, param.m - 1, param.d))
	} else {
		paramDate = today
	}

	const [currentDate, setCurrentDate] = useState(paramDate)

	function handleOnKeyDown(e) {
		// Left
		if (e.which === 37) {
			handlePrevDayButton()
		}
		// Right
		if (e.which === 39) {
			handleNextDayButton()
		}
	}
	window.onkeydown = (e) => handleOnKeyDown(e)

	function handlePrevDayButton() {
		let day = currentDate
		let prevDay = subDays(day, 1)
		setCurrentDate(prevDay)
		navigate(`${format(prevDay, "yyyy/MM/dd")}`, { replace: true })
	}

	function handleNextDayButton() {
		if (isToday(currentDate)) return
		let day = currentDate
		let nextDay = addDays(day, 1)
		setCurrentDate(nextDay)
		navigate(format(nextDay, "yyyy/MM/dd"), { replace: true })
	}

	function handleResetButton() {
		if (isToday(currentDate)) return
		setCurrentDate(today)
		navigate(format(today, "yyyy/MM/dd"), { replace: true })
	}

	function handleDateFormat(date) {
		if (isToday(date)) {
			return "Today"
		} else if (isYesterday(date)) {
			return "Yesterday"
		} else {
			return format(date, "E, d MMM")
		}
	}

	useEffect(() => {
		document.title = `History • ${APP_NAME}`
	}, [])

	return (
		<>
			<HistoryNav />
			<div className="history-page main-content">
				<div className="container">
					<nav className="dateNav">
						<div className="prev" onClick={() => handlePrevDayButton()}>
							<FontAwesomeIcon icon={`fas fa-arrow-left`} />
						</div>
						<div className="date" onClick={() => handleResetButton()}>
							{handleDateFormat(currentDate)}
						</div>
						<div
							className={`next${isToday(currentDate) ? " hide" : ""}`}
							onClick={() => handleNextDayButton()}>
							<FontAwesomeIcon icon={`fas fa-arrow-right`} />
						</div>
					</nav>

					<main>
						<div className="visits">
							<Outlet context={currentDate} />
						</div>
					</main>
				</div>
			</div>
		</>
	)
}
