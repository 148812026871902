import React, { useState } from "react"
import BeersIcon from "./Beers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import WinesIcon from "./Wines"
import { CURRENT_MODE_STORAGE_KEY } from "../../constants/paths"
import Spinner from "../Spinner"

const Stat = ({ icon, number, title, loading }) => {
	return (
		<div className="stat">
			<div className="icon">
				{loading ? (
					<Spinner white />
				) : (
					<FontAwesomeIcon icon={`fas fa-${icon}`} />
				)}
			</div>
			<>
				<div className="title">{title}</div>
				<div className="number">{loading ? "Loading..." : number}</div>
			</>
		</div>
	)
}

const Stats = ({
	amountDrank,
	attendedVenues,
	unitsConsumed,
	metUpFriends,
	currentDate,
	comments,
	loading,
}) => {
	const storedIsWine = localStorage.getItem(CURRENT_MODE_STORAGE_KEY)
	const initialIsWine = storedIsWine ? JSON.parse(storedIsWine) : true
	// eslint-disable-next-line
	const [isWine, setIsWine] = useState(initialIsWine)

	return (
		<div className="statsW">
			{/* beers drank */}
			{!isWine ? (
				<BeersIcon
					ml={amountDrank}
					currentDate={currentDate}
					comments={comments}
					loading={loading}
				/>
			) : (
				<WinesIcon
					ml={amountDrank}
					currentDate={currentDate}
					comments={comments}
					loading={loading}
				/>
			)}

			<div className="stats">
				{/* venues visited */}
				<Stat
					icon="store"
					title="Attended"
					number={
						attendedVenues > 0
							? `${attendedVenues} venue${attendedVenues === 1 ? "" : "s"}`
							: "Nowhere"
					}
					loading={loading}
				/>

				{/* units consumed */}
				<Stat
					icon="bottle-droplet"
					title="Alcohol"
					number={
						unitsConsumed > 0
							? `${unitsConsumed} unit${unitsConsumed === 1 ? "" : "s"}`
							: "None"
					}
					loading={loading}
				/>

				{/* friends met */}
				<Stat
					icon={metUpFriends < 2 ? "user" : "user-group"}
					title={"Met up with"}
					number={
						metUpFriends > 0
							? `${metUpFriends} friend${metUpFriends === 1 ? "" : "s"}`
							: "No one"
					}
					loading={loading}
				/>
			</div>
		</div>
	)
}

export default Stats
