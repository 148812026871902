import { isToday } from "date-fns"
import React, { useEffect, useState } from "react"
import mlToL from "../../helpers/mlToL"

export const BeerIcon = ({
	percentage,
	amount,
	type,
	currentDate,
	comments,
	loading,
}) => {
	const [comment, setComment] = useState("Thirsty?")
	useEffect(() => {
		let comments = [
			"Parched through and through...",
			"A day devoid of delight",
			"Not a drop to quench the thirst",
			"Gasping for a gulp",
			"Drinkless",
		]
		setComment(
			isToday(currentDate)
				? "Thirsty?"
				: comments[Math.floor(Math.random() * comments.length)]
		)
	}, [currentDate])

	let amountW
	switch (type) {
		case "half":
			amountW = `${amount}ml`
			break
		case "full":
			amountW = `${amount} pint${amount !== 1 ? "s" : ""}`
			break
		case "keg":
			amountW = `${amount} keg${amount !== 1 ? "s" : ""} (30L)`
			break
		default:
			amountW = ""
			break
	}

	return (
		<div className={`beer ${!loading && type}`}>
			{!loading && (
				<div className="liquidW">
					<div className="liquid" style={{ height: `${percentage}%` }} />
				</div>
			)}
			<div className="glass" />
			<div className="amount">
				{loading ? (
					" "
				) : (
					<>
						{amountW}
						{comments && (
							<div className="comment">{type === "empty" && `${comment}`}</div>
						)}
					</>
				)}
			</div>
		</div>
	)
}

const BeersIcon = ({ ml, currentDate, comments, title, loading }) => {
	const keg = 30000
	const pint = 500

	let total_of_kegs = ml / keg
	let total_of_kegs_exact = Math.floor(total_of_kegs)
	let total_of_pints = ((total_of_kegs - total_of_kegs_exact) * keg) / pint

	let total_of_pints_exact = Math.floor(total_of_pints)
	let total_of_ml = Math.round((total_of_pints - total_of_pints_exact) * pint)

	const percentage = (total_of_ml / pint) * 100

	return (
		<div className="beersW">
			<div className="circleW">
				<div className="cicle" />
			</div>
			<div className="title">
				{
					<>
						{`Amount drank ${title ? "at" : ":"}`}
						{title ? (
							<span>{title}</span>
						) : (
							<span>
								{loading
									? "calculating..."
									: ml > 0
									? mlToL(ml)
									: `nothing${isToday(currentDate) ? " yet" : ""}...`}
							</span>
						)}
					</>
				}
			</div>
			<div className="beers">
				{ml > 0 ? (
					<>
						{total_of_kegs_exact > 0 && (
							<>
								<BeerIcon
									type="keg"
									amount={total_of_kegs_exact}
									comments={comments}
								/>
								{(total_of_ml > 0 || total_of_pints_exact > 0) && (
									<div className="text">{"+"}</div>
								)}
							</>
						)}
						{total_of_pints_exact > 0 && (
							<>
								<BeerIcon
									type="full"
									amount={total_of_pints_exact}
									percentage={100}
									comments={comments}
								/>
								{total_of_ml > 0 && <div className="text">{"+"}</div>}
							</>
						)}
						{total_of_ml > 0 && (
							<BeerIcon
								type="half"
								amount={total_of_ml}
								percentage={percentage}
								comments={comments}
							/>
						)}
					</>
				) : (
					<BeerIcon
						type="empty"
						percentage={0}
						currentDate={currentDate}
						comments={comments}
						loading={loading}
					/>
				)}
			</div>
		</div>
	)
}

export default BeersIcon
