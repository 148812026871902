import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Spinner from "../Spinner"
import { useNavigate } from "react-router-dom"

const AccountsNav = ({ title, onSubmit, loading }) => {
	const navigate = useNavigate()
	function handleCancel() {
		navigate(-1)
	}

	return (
		<div className="accounts-nav">
			<div className="cancel" onClick={(e) => handleCancel(e)}>
				<FontAwesomeIcon icon={"arrow-left"} />
			</div>
			<div className="title">{title}</div>
			<div className="confirm" onClick={onSubmit}>
				{!loading ? (
					<FontAwesomeIcon icon={`fas fa-check`} />
				) : (
					<Spinner fullPage={false} />
				)}
			</div>
		</div>
	)
}

export default AccountsNav
