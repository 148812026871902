import React from "react"
import {
	createBrowserRouter,
	createRoutesFromElements,
	RouterProvider,
	Route,
	redirect,
	Navigate,
} from "react-router-dom"
import { format } from "date-fns"
import { supabase } from "./lib/supabaseClient"

import * as ROUTES from "./constants/routes"
import Root from "./routes/root"

// import NotFound from "./pages/not-found"

import LandingPage from "./pages/auth/landing-page"
import LoginPage from "./pages/auth/login-page"

import DashboardPage from "./pages/dashboard/dashboard-page"
import AddDrinkPage from "./pages/dashboard/add-drink-page"
import AddLocationPage from "./pages/dashboard/add-location-page"
import AddProfilesPage from "./pages/dashboard/add-profiles-page"
import NewDrinkPage from "./pages/dashboard/new-drink-page"
import NewLocationPage from "./pages/dashboard/new-location-page"

import ExploreLayout from "./pages/explore/explore-layout"
import ResultsProfilesPage from "./pages/explore/results-profiles-page"
import ResultsLocationsPage from "./pages/explore/results-locations-page"
import ResultsDrinksPage from "./pages/explore/results-drinks-page"
import ProfilePage from "./pages/explore/profile-page"
import LocationPage from "./pages/explore/location-page"
import DrinkPage from "./pages/explore/drink-page"

import EditProfile from "./pages/me/edit"
import HistoryVisit from "./pages/history/visit"
import MeLayout from "./pages/me/me-layout"
import MePage from "./pages/me/me-page"
import EditVisit from "./pages/accounts/edit-visit"
import AddWinePage from "./pages/dashboard/add-wine-page"
import AuthLayout from "./pages/auth/auth-layout"
import executeQuery from "./helpers/executeQuery"
import DashboardLayout from "./pages/dashboard/dashboard-layout"
import HistoryPage from "./pages/history/history-page"
import ProfileLayout from "./pages/explore/profile-layout"

export default function App() {
	let today = new Date()
	today = today.getTime()
	let todayURL = format(today, "yyyy/MM/dd")

	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				{/* AUTH */}
				<Route element={<AuthLayout />}>
					<Route index element={<LandingPage />} />
					<Route path={ROUTES.LOGIN} element={<LoginPage />} />
				</Route>

				{/* ROOT */}
				<Route element={<Root />}>
					{/* DASHBOARD */}
					<Route index element={<Navigate to={ROUTES.DASHBOARD} replace />} />
					<Route path={ROUTES.DASHBOARD} element={<DashboardLayout />}>
						<Route element={<DashboardPage />} index />

						<Route element={<AddLocationPage />} path={"add-location"} />
						<Route element={<NewLocationPage />} path={"new-location"} />

						<Route element={<AddProfilesPage />} path={"add-attendees"} />

						<Route element={<AddDrinkPage />} path={"add-drink"} />
						<Route element={<AddWinePage />} path={"add-wine"} />
						<Route element={<NewDrinkPage />} path={"new-drink"} />
					</Route>

					{/* ME */}
					<Route path={`me`} element={<MeLayout />}>
						<Route index element={<MePage />} />
					</Route>

					{/* ACCOUNTS */}
					<Route path={"accounts"}>
						<Route path={"edit"} element={<EditProfile />} />
					</Route>

					{/* HISTORY */}
					<Route path={`${ROUTES.HISTORY}`} element={<HistoryPage />}>
						<Route
							index
							element={
								<Navigate to={`${ROUTES.HISTORY}/${todayURL}`} replace />
							}
						/>

						<Route
							path=":y/"
							errorElement={<Navigate to={`${ROUTES.HISTORY}/${todayURL}`} />}>
							<Route
								index
								element={
									<Navigate to={`${ROUTES.HISTORY}/${todayURL}`} replace />
								}
							/>
						</Route>
						<Route
							path=":y/:m"
							errorElement={<Navigate to={`${ROUTES.HISTORY}/${todayURL}`} />}
						/>
						<Route
							path=":y/:m/:d"
							element={<HistoryVisit />}
							errorElement={<Navigate to={`${ROUTES.HISTORY}/${todayURL}`} />}
						/>
						<Route path=":y/:m/:d/edit" element={<EditVisit />} />
					</Route>

					{/* EXPLORE */}
					<Route path={`${ROUTES.EXPLORE}`} element={<ExploreLayout />}>
						<Route path={`locations`} element={<ResultsLocationsPage />} />
						<Route path={`drinks`} element={<ResultsDrinksPage />} />
						<Route index element={<Navigate to="profiles" replace />} />
						<Route path={`profiles`} element={<ResultsProfilesPage />} />
					</Route>

					<Route
						path={`${ROUTES.PROFILE}/:username`}
						element={<ProfileLayout />}>
						<Route index element={<ProfilePage />} />
					</Route>

					<Route
						loader={async ({ params }) => {
							const { data } = await executeQuery(
								supabase
									.from("locations")
									.select(
										`id,
									name,
									address,
									wifi,
									website,
									instagram,
									updated_at,
									favourite_count,
									available_drinks,
									type,
									location_types (
										name
									)
								`
									)
									.eq("id", params.id)
									.single()
							)
							if (!data) {
								return redirect(ROUTES.NOT_FOUND)
							} else {
								const coordinates = await fetch(
									`https://api.mapbox.com/geocoding/v5/mapbox.places/$${data.address}.json?access_token=pk.eyJ1IjoidG9tdW5nZXJlciIsImEiOiJjbGM2dmE5NGcybTZuM3FwOHc4YWY0a3drIn0.K1kFbu6oiqXAFhmaxdxsVA`
								)
									.then((response) => {
										return response.json()
									})
									.then((data) => {
										return data.features[0].center
									})

								return {
									data: data,
									coordinates: coordinates,
								}
							}
						}}
						path={`${ROUTES.LOCATION}/:id`}
						element={<LocationPage />}
					/>

					<Route
						loader={async ({ params }) => {
							const { data } = await executeQuery(
								supabase
									.from("drinks")
									.select(
										`name, alcohol_percentage, id, updated_at, favourite_count, 
								type,
								drink_types (
									name
								),
								country,
								countries (
									name_en
								)`
									)
									.eq("id", params.id)
									.single()
							)
							if (!data) {
								return redirect(ROUTES.NOT_FOUND)
							} else {
								return data
							}
						}}
						path={`${ROUTES.DRINK}/:id`}
						element={<DrinkPage />}
					/>
				</Route>
			</>
		)
	)

	return <RouterProvider router={router} />
}
