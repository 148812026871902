export default async function executeQuery(query) {
	try {
		// Initialize loading state to true
		let loading = true

		// Set loading to false when the data is fetched
		const { data, error } = await query

		// Data has been fetched, set loading to false
		loading = false

		if (error) {
			console.error(error)
		}

		return { data, loading }
	} catch (error) {
		console.error(error)
		throw new Error("An error occurred while processing the request.")
	}
}
