import React from "react"

const HistoryNav = () => {
	return (
		<div className="history-nav">
			<div className="title">{`History`}</div>
		</div>
	)
}

export default HistoryNav
