import { createContext, useContext, useEffect, useState } from "react"
import { supabase } from "../lib/supabaseClient"

const SessionContext = createContext()

export const SessionProvider = ({ children }) => {
	const [session, setSession] = useState(null)

	useEffect(() => {
		supabase.auth.getSession().then(({ data }) => {
			setSession(data.session)
		})

		const {
			data: { subscription },
		} = supabase.auth.onAuthStateChange((_event, session) => {
			setSession(session)
		})

		return () => subscription.unsubscribe()
	}, [])

	return (
		<SessionContext.Provider value={{ session }}>
			{children}
		</SessionContext.Provider>
	)
}

export const useSession = () => {
	const context = useContext(SessionContext)
	if (!context) {
		throw new Error("useSession must be used within a SessionProvider")
	}
	return context
}
