import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"
import * as ROUTES from "../../constants/routes"

const ProfileNav = ({ user }) => {
	const navigate = useNavigate()
	function editProfile() {
		navigate(ROUTES.EDIT_PROFILE)
	}

	return (
		<div className="profile-nav">
			<div className="title">{`@${user ? user.username : "me"}`}</div>
			<div className="menu" onClick={editProfile}>
				<FontAwesomeIcon icon={`pen`} />
			</div>
		</div>
	)
}

export default ProfileNav
