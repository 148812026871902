import React from "react"
import ToggleSwitch from "../ToggleSwitch"
import { useOutletContext } from "react-router-dom"

const SiteNav = () => {
	const { user, setIsWine, isWine } = useOutletContext()

	return (
		<div className="siteNav">
			<div className="greeting">
				{user?.f_name && (
					<>
						<span>Hello,</span>
						{user?.f_name}
					</>
				)}
			</div>
			<ToggleSwitch isWine={isWine} setIsWine={setIsWine} />
		</div>
	)
}

export default SiteNav
