import { format, parseISO } from "date-fns"
import React, { useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"
import { supabase } from "../../lib/supabaseClient"
import { useSession } from "../../context/SessionContext"
import executeQuery from "../../helpers/executeQuery"
import Visit from "../../components/history/Visit"
import Spinner from "../../components/Spinner"

export default function HistoryVisit() {
	const currentDate = useOutletContext()
	const { session } = useSession()
	let userId = session?.user.id

	const [loading, setLoading] = useState(true)

	// GET USER VISITS
	const [fetched, setFetched] = useState(false)
	const [visits, setVisits] = useState([])
	const [totalVisits, setTotalVisits] = useState([])
	const [todaysVisits, setTodaysVisits] = useState([])

	// GET VISITS
	useEffect(() => {
		async function fetchVisits() {
			if (userId) {
				try {
					setLoading(true)
					const { data } = await executeQuery(
						supabase
							.from("user_visits")
							.select(
								"id, timestamp, location:locations(id, name), drinks:user_visit_drinks(id, ml, timestamp, drink:drinks(alcohol_percentage, name)), attendees:user_visit_attendees(id, user:profiles(user_id, username, avatar))"
							)
							.eq("user_id", userId)
					)
					setVisits(data)
					setLoading(false)
					setFetched(true)
				} catch (error) {
					console.error("Error fetching data:", error)
					setLoading(false)
				}
			} else {
				setVisits([])
			}
		}

		if (!fetched && userId) {
			fetchVisits()
		}
	}, [fetched, userId])

	// GET TOTAL VISITS
	useEffect(() => {
		function createVisitObject(obj) {
			const attendees = obj.attendees
			const drinks = obj.drinks
			const id = obj.id
			const location_id = obj.location.id
			const location_name = obj.location.name
			const timestamp = obj.timestamp

			let updatedAttendees = []
			if (attendees.length > 0) {
				updatedAttendees = attendees.map((item) =>
					createAttendeeObject(item, timestamp)
				)
			}

			let updatedDrinks = []
			if (drinks.length > 0) {
				updatedDrinks = drinks.map((item) => createDrinkObject(item))
			}

			return {
				attendees: updatedAttendees,
				drinks: updatedDrinks,
				id,
				location: {
					id: location_id,
					name: location_name,
				},
				timestamp,
			}
		}

		function createAttendeeObject(obj, timestamp) {
			const id = obj.id
			const user_id = obj.user.user_id
			const username = obj.user.username
			const avatar = obj.user.avatar

			return {
				id,
				timestamp,
				user_id,
				username,
				avatar,
			}
		}

		function createDrinkObject(obj) {
			const alcohol_percentage = obj.drink.alcohol_percentage
			const id = obj.id
			const name = obj.drink.name
			const timestamp = obj.timestamp
			const ml = obj.ml

			const units = (0.8 * alcohol_percentage * ml) / 1000
			return {
				alcohol_percentage,
				id,
				ml,
				name,
				timestamp,
				units,
			}
		}

		let updateVisits = []
		if (visits) {
			updateVisits = visits.map((visit) => createVisitObject(visit))
		}
		if (totalVisits.length > 0) {
			return
		} else {
			setTotalVisits(updateVisits)
		}
	}, [visits, totalVisits.length])

	// GET TODAYS VISITS
	useEffect(() => {
		let totalVisitsToday = []
		if (totalVisits.length > 0) {
			totalVisits.forEach((item) => {
				if (
					[format(currentDate, "P")].includes(
						format(parseISO(item.timestamp), "P")
					)
				) {
					totalVisitsToday.push(item)
				}
			})
			setTodaysVisits(totalVisitsToday)
		}
	}, [totalVisits, currentDate])

	return loading ? (
		<Spinner />
	) : (
		todaysVisits.map((visit) => <Visit key={visit.id} visit={visit} />)
	)
}
