import React, { useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"
import Stats from "../../components/profile/Stats"
import { supabase } from "../../lib/supabaseClient"
import executeQuery from "../../helpers/executeQuery"

export default function ProfilePage() {
	const userId = useOutletContext()

	const [loading, setLoading] = useState(true)

	// GET USER VISITS
	const [totalVisits, setTotalVisits] = useState([])
	const [totalDrinks, setTotalDrinks] = useState([])
	const [totalAttendees, setTotalAttendees] = useState([])
	const [totalMl, setTotalMl] = useState(0)
	const [totalUnits, setTotalUnits] = useState(0)
	const [totalVenues, setTotalVenues] = useState(0)

	// GET TOTAL VISITS
	useEffect(() => {
		function createVisitObject(obj) {
			const attendees = obj.attendees
			const drinks = obj.drinks
			const id = obj.id
			const location_id = obj.location.id
			const ml = obj.ml
			const timestamp = obj.timestamp

			let updatedAttendees = []
			if (attendees.length > 0) {
				updatedAttendees = attendees.map((item) =>
					createAttendeeObject(item, timestamp)
				)
			}

			let updatedDrinks = []
			if (drinks.length > 0) {
				updatedDrinks = drinks.map((item) => createDrinkObject(item))
			}

			return {
				attendees: updatedAttendees,
				drinks: updatedDrinks,
				id,
				location_id,
				ml,
				timestamp,
			}
		}

		function createAttendeeObject(obj, timestamp) {
			const id = obj.id
			const user_id = obj.user.user_id
			const username = obj.user.username

			return {
				id: id,
				timestamp: timestamp,
				user_id: user_id,
				username: username,
			}
		}

		function createDrinkObject(obj) {
			const alcohol_percentage = obj.drink.alcohol_percentage
			const id = obj.id
			const timestamp = obj.timestamp
			const ml = obj.ml

			const units = (0.8 * alcohol_percentage * ml) / 1000
			return {
				alcohol_percentage: alcohol_percentage,
				id: id,
				ml: ml,
				timestamp: timestamp,
				units: units,
			}
		}

		async function fetchVisits() {
			if (userId) {
				try {
					setLoading(true)

					const { data: userVisitsData } = await executeQuery(
						supabase
							.from("user_visits")
							.select(
								"id, timestamp, location:locations(id, name), drinks:user_visit_drinks(id, ml, timestamp, drink:drinks(alcohol_percentage)), attendees:user_visit_attendees(id, user:profiles(user_id, username))"
							)
							.eq("user_id", userId)
					)
					const formattedVisits = userVisitsData.map((visit) =>
						createVisitObject(visit)
					)
					setTotalVisits(formattedVisits)
					setLoading(false)
				} catch (error) {
					console.error("Error fetching data:", error)
					setLoading(false)
				}
			} else {
				setTotalVisits([])
			}
		}

		if (totalVisits.length === 0) {
			fetchVisits()
		}
	}, [totalVisits.length, userId])

	// GET TOTAL DRINKS
	useEffect(() => {
		const drinksArray = []

		for (const drinkLog of totalVisits) {
			for (const drink of drinkLog.drinks) {
				drinksArray.push(drink)
			}
		}
		setTotalDrinks(drinksArray)
	}, [totalVisits])

	// GET TOTAL ATTENDEES
	useEffect(() => {
		const attendeesArray = []

		for (const attendeeLog of totalVisits) {
			for (const attendee of attendeeLog.attendees) {
				attendeesArray.push(attendee)
			}
		}
		setTotalAttendees(attendeesArray)
	}, [totalVisits])

	// GET TOTAL ML
	useEffect(() => {
		let array = totalDrinks
		let ml = array.reduce((acc, item) => acc + item.ml, 0)
		setTotalMl(ml)
	}, [totalDrinks])

	// GET TOTAL UNITS
	useEffect(() => {
		let array = totalDrinks
		let units = array.reduce((acc, item) => acc + item.units, 0)
		units = Math.round((units + Number.EPSILON) * 10) / 10
		setTotalUnits(units)
	}, [totalDrinks])

	// GET TOTAL VENUES
	useEffect(() => {
		let array = new Set()
		for (const item of totalVisits) {
			if (item.location_id !== null) {
				array.add(item.location_id)
			}
		}
		setTotalVenues(array.size)
	}, [totalVisits])

	return (
		<>
			<Stats
				amountDrank={totalMl}
				attendedVenues={totalVenues}
				metUpFriends={totalAttendees}
				unitsConsumed={totalUnits}
				comments={false}
				loading={loading}
			/>
		</>
	)
}
